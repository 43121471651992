import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, List, Spin } from "antd"; // Import Spin for loading state
import { Link } from "react-router-dom";

const { Title } = Typography;

const RecentBlogs = () => {
  const [recentBlogs, setRecentBlogs] = useState([]); // Default state is an empty array
  const [loading, setLoading] = useState(true); // Track loading state

  // Use useEffect to fetch data on component mount
  useEffect(() => {
    const fetchRecentBlogs = async () => {
      try {
        const response = await axios.get("https://dashboard.hakimethio.et/api/blogs");
        console.log(response.data, "recent blogs");
        setRecentBlogs(response.data);
      } catch (error) {
        console.error("Error fetching recent blogs:", error);
      } finally {
        setLoading(false); // Stop loading once the data is fetched
      }
    };

    fetchRecentBlogs();
  }, []); // Empty dependency array to run once on mount

  return (
    <div>
      {/* <Title level={4} className="text-black">
        Recent Articles
      </Title> */}

      {loading ? ( // Show loading spinner while data is being fetched
        <Spin size="large" />
      ) : (
        <List
          dataSource={recentBlogs}
          renderItem={(item) => (
            <List.Item>
              <Link to={`/blog/${item.id}`} className="text-black">
                {item.title}
              </Link>
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default RecentBlogs;
